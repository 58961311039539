import React from "react";
import MyOrderList from "../components/MyOrderList";
import MyAddToCart from "../components/MyAddToCart";

function MyCart() {
  return (
    <div className="mt-4">
      <MyAddToCart />
    </div>
  );
}

export default MyCart;
