import React from "react";
import MyOrderList from "../components/MyOrderList";


function MyOrder() {
  return (
    <div className="mt-4">
      <MyOrderList/>
    </div>
  );
}

export default MyOrder;
